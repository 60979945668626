import { useMemo, useState } from "react"

import { TCreateClientForm } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientDialog"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { Combobox } from "src/ui/Combobox/Combobox"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseCreateClientOwner({
  owner,
  ownerType,
  onChange,
  disabled,
}: {
  owner: Maybe<TCreateClientForm["owner"]>
  ownerType: OwnerType
  onChange: (selectedOwner: Omit<TCreateClientForm["owner"], "type">) => void
  disabled?: boolean
}) {
  const [searchedInput, setSearchedInput] = useState("")

  const fetchUsers = useFetchParadiseUsers({
    filter: {
      email: searchedInput,
    },
    options: {
      enabled: ownerType === OwnerType.USER,
    },
  })

  const fetchOrganizations = useFetchOrganizations({
    params: {
      id: searchedInput,
    },
    options: {
      enabled: ownerType === OwnerType.ORGANIZATION,
    },
  })

  const options = useMemo(() => {
    if (ownerType === OwnerType.ORGANIZATION) {
      return (
        fetchOrganizations.data?.organizations.map((org) => ({
          value: org.id,
          label: org.id,
        })) ?? []
      )
    }

    return (
      fetchUsers.data?.users.map((user) => ({
        value: user.user_id,
        label: user.email,
      })) ?? []
    )
  }, [ownerType, fetchOrganizations, fetchUsers])

  return (
    <Combobox
      selectedValue={owner?.id ?? ""}
      options={options}
      onSearch={setSearchedInput}
      onChange={(value) => {
        const selectedOwner = options.find((o) => o.value === value)

        if (selectedOwner) {
          onChange({
            id: selectedOwner.value,
            email: selectedOwner.label,
          })
          setSearchedInput("")
        }
      }}
      required
      disabled={disabled}
    />
  )
}
