import { Select, Switch, TextField } from "@material-ui/core"
import { FormikProps } from "formik"

import { MultiPillSelect } from "src/components/Paradise/MultiPillSelect"
import { TCreateClientForm } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientDialog"
import { FormItem } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseFormItem"
import { MText } from "src/ui/MText"

const NUMBER_OF_VERSIONS = 8

export function ParadiseClientSettings({
  form,
}: {
  form: FormikProps<TCreateClientForm>
}) {
  return (
    <>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.enabled}
            name="enabled"
            onChange={form.handleChange}
          />
        }
        description="Toggles the client. If unchecked no tokens issued to the client will work anymore."
      />
      <FormItem
        title="Restricted to owner"
        control={
          <Switch
            checked={form.values.restricted_to_owner}
            name="restricted_to_owner"
            onChange={(e, checked) => {
              form.setFieldValue("restricted_to_owner", checked)

              if (!form.values.first_party && !checked) {
                form.setFieldValue("owner_password_grant.enabled", false)
              }
            }}
          />
        }
        description="If this is checked then only the user owning the client, or any member of the organization owning the client can issue tokens using this client."
      />
      <FormItem
        title="First party client"
        control={
          <Switch
            checked={form.values.first_party}
            name="first_party"
            onChange={(e, checked) => {
              form.setFieldValue("first_party", checked)
              form.setFieldValue("can_access_third_party_data", true)

              if (!form.values.restricted_to_owner && !checked) {
                form.setFieldValue("owner_password_grant.enabled", false)
              }
            }}
            disabled={!form.values.owner.email.includes("@minut.com")}
          />
        }
        description={
          <>
            <MText variant="body">
              This should only be checked for clients owned by Minut. Checking
              this box will:
            </MText>
            <ul>
              <li>Disable requirement for a subscription</li>
              <li>
                Disable showing the consent view when authorizing the client
              </li>
            </ul>
          </>
        }
      />
      <FormItem
        title="Can Access 3:rd Party Data"
        control={
          <Switch
            checked={form.values.can_access_third_party_data}
            name="can_access_third_party_data"
            onChange={form.handleChange}
            disabled={form.values.first_party}
          />
        }
        description={
          <>
            <MText variant="body">
              Note: This will always be enabled for 1:st party clients
            </MText>
            <MText variant="body">
              If this is checked then the client will be able to fetch guests
              originating from integrations. Otherwise they will only see guests
              originating from the Minut app.
            </MText>
          </>
        }
      />
      <FormItem
        title="Minimum API version"
        control={
          <Select
            native
            value={form.values.minimum_api_version}
            name="minimum_api_version"
            onChange={(e) => {
              form.setFieldValue(
                "minimum_api_version",
                parseInt(e.target.value as string)
              )
            }}
            fullWidth
          >
            {[...new Array(NUMBER_OF_VERSIONS).keys()].reverse().map((v) => {
              const _v = v + 1
              return (
                <option value={_v} key={_v}>
                  Version {_v}
                </option>
              )
            })}
            <option value={-1}>None (All versions allowed)</option>
          </Select>
        }
        description="This should generally be the latest (highest) version for external clients. For internal clients this should not be set, as those needs to be able to use all versions."
      />
      <FormItem
        title="Client roles"
        control={
          <MultiPillSelect
            value={form.values.roles.map((role) => ({
              id: role,
              name: role,
            }))}
            options={[]}
            onSearch={() => {}}
            onSelected={(selected) => {
              form.setFieldValue(
                "roles",
                selected.map((s) => s.id)
              )
            }}
            onCreate={(input) => {
              form.setFieldValue("roles", [...form.values.roles, input])
            }}
            loading={false}
          />
        }
        description="The roles for the client, not to be confused with roles on the user."
      />
      <FormItem
        title="Required user role"
        control={
          <TextField
            name="user_required_role"
            onChange={form.handleChange}
            fullWidth
          />
        }
        description="If this is set any user authenticating with this client must have this role set, otherwise they'll get an error when trying to issue a token."
      />
    </>
  )
}
